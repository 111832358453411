











import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import LoadStatusMessage from '@/components/common/LoadStatusMessage.vue'

@Component({
  components: {
    WidgetTitle,
    LoadStatusMessage
  }
})
export default class Order extends Vue {
  get isUserLogged(): boolean {
    return this.$store.getters.userToken
  }

  mounted(): void {
    this.$store.dispatch('clearCart')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ym(40089035, 'reachGoal', 'cake_buy')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.gtag('event', 'buy', { event_category: 'order' })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.roistat.event.send('cake_buy')
  }
}
